import React, { useState, useRef } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Tabs } from "antd";

import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import Transaction from "components/Transaction/Transaction";
import SearchViaDate from "components/Filter/SearchViaDate";
import moment from "moment";

function AllTransaction() {
  const printRef = useRef(null);
  const [AllCreditTransaction, setAllCreditTransaction] = useState([]);
  const [AllDebitTransaction, setAllDebitTransaction] = useState([]);
  const [ready, setReady] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  // GetAllOrder

  const GetAllOrder = async (from, end) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllTransaction(from, end);
      const credit = res.data?.data?.filter(
        (item) => item.transaction_mode === "credit"
      );
      setAllCreditTransaction(credit);
      const debit = res.data?.data?.filter(
        (item) => item.transaction_mode === "debit"
      );
      setAllDebitTransaction(debit);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  };
  const items = [
    {
      key: "1",
      label: `Credit`,
      children: (
        <div ref={printRef}>
          <Transaction transaction={AllCreditTransaction} isLoading={ready} />
        </div>
      ),
    },
    {
      key: "2",
      label: `Debit`,
      children: (
        <div ref={printRef}>
          <Transaction transaction={AllDebitTransaction} isLoading={ready} />
        </div>
      ),
    },
  ];
  const SearchDate = async () => {
    if (fromDate && toDate) {
      GetAllOrder(fromDate, toDate);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Invoice",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });
  const csvData = [
    [
      "ID",
      "Date",
      "Amount",
      "Mode",
      "Channel",
      "Name",
      "Email",
      "Phone",
      "Account Number",
      "Bank Name",
      "Ifsc Code",
    ],
    ...AllCreditTransaction?.map((item, idx) => [
      idx + 1,
      moment(item?.created_at).format("ll"),
      item?.amount,
      item?.transaction_mode,
      item?.channel,
      item?.distributor?.name,
      item?.distributor?.email,
      item?.distributor?.phone,
      item?.distributor?.account_number,
      item?.distributor?.bank_name,
      item?.distributor?.ifsc_code,
    ]),
  ];
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Button color="white" onClick={handlePrint}>
              Export PDF
            </Button>

            <CSVLink
              className="btn btn-white"
              filename="order.csv"
              data={csvData}
            >
              Export to CSV
            </CSVLink>
            <Button color="white" onClick={handlePrint}>
              Print
            </Button>
          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <SearchViaDate
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    action={SearchDate}
                  />
                </Row>
              </CardHeader>
              <Card className="shadow">
                <CardBody>
                  <Tabs defaultActiveKey="1" items={items} />
                </CardBody>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllTransaction;
