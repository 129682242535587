import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Button,
} from "reactstrap";

import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import Dataservices from "../../services/requestApi";
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import SearchViaDate from "components/Filter/SearchViaDate";
import { Table, message, notification } from "antd";
function AllDistributor() {
  const Jwt = require("jsonwebtoken");
  const printRef = useRef(null);
  const [AllUser, setAllUser] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [AllRank, setAllRank] = useState([]);
  const [filteredParentName, setFilteredParentName] = useState("");
  const [AllUserDup, setAllUserDup] = useState([]);
  const [userEmailfiltered, setUserEmail] = useState({ 'emailId': '', 'password': '' });
  const [loading, setLoading] = useState(false);
  // Fetch all ranks
  const getAllRank = useCallback(async () => {
    try {
      setReady(true);
      const res = await Dataservices.getAllRank();
      setAllRank(res?.data?.Admin);
    } catch (e) {
      console.log(e);
    } finally {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    getAllRank();
  }, [getAllRank]);

  // Fetch all users
  const getAllUSERS = useCallback(async (from, end) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllDistributor(from, end);
      if (res.data?.message === "Success !") {
        setAllUser(res.data?.Distributor);
        setAllUserDup(res.data?.Distributor);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    getAllUSERS();
  }, [getAllUSERS]);

  // Update distributor information
  const DistributorUpdate = async (e, id, MetaData, type) => {
    e.preventDefault();
    try {
      const Data = type === "rank" ? { distributor_rank_id: MetaData } : { status: !MetaData };
      const { data } = await Dataservices.updateDistributor(id, queryString.stringify(Data));
      if (data) {
        message.success("Distributor updated successfully");
        getAllUSERS();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Filtered search results
  const searchFilter = AllUser.filter((data) => {

    const phone = data?.phone?.toLowerCase();
    const name = data?.name?.toLowerCase();
    const parentName = data?.parent_id?.name?.toLowerCase();
    const searchTerm = search.toLowerCase();
    const parentFilter = filteredParentName.toLowerCase();
    const matchesSearch = phone.includes(searchTerm) || name.includes(searchTerm) || parentName?.includes(searchTerm);
    const matchesParentFilter = !filteredParentName || parentName === parentFilter;
    return matchesSearch && matchesParentFilter;
  });

  // Search by date
  const SearchDate = async () => {
    if (fromDate && toDate) {
      getAllUSERS(fromDate, toDate);
    }
  };

  let userEmailA;
  const login = async (item) => {
    const secretKey = "6739542a44803877bda6471173298dd23a9b406b9260ecbdade71303bc8b4581";
    setUserEmail({ emailId: item.email, password: item.password });

    const token = Jwt.sign(
      { email: item.email, distributor_id: item._id },
      secretKey,
      { expiresIn: "120d" }
    );
    notification.open({
      type: "success",
      message: "Success",
      description: "You are Login Successfully",
    });
    window.open(
      `https://efckart.com/?token=${token}&user_id=${item?._id}&user_name=${item?.name}&user_type=${item?.type}&user_rank=${item.distributor_rank_id.rank}&user_discount=${item.distributor_rank_id.ediscount}` // <- This is what makes it open in a new window.
    );

  }


  // Handle parent name click
  const handleParentNameClick = (parentName, userEmail) => {
    setSearch(null || ''); //when click handleParentNameClick it clear search input field
    setAllUser(AllUserDup.filter((item) => item.code == parentName));
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Rank", dataIndex: "distributor_rank_id", key: "distributor_rank_id", render: (item) => item?.rank },
    { title: "DOJ", dataIndex: "created_at", key: "created_at", render: (created_at) => moment(created_at).format("ll") },
    {
      title: "Parent Name",
      dataIndex: "parent_id",
      key: "parent_id",
      render: (item) => (
        <span onClick={() => handleParentNameClick(item?.code)} style={{ cursor: 'pointer', color: 'blue' }}>
          {item?.name ?? "NA"}
        </span>
      ),
    },
    { title: "AadharCard Number", dataIndex: "aadhar_no", key: "adhar_no" },
    { title: "Pan Number", dataIndex: "pan_no", key: "pan_no" },
    { title: "Bank Name", dataIndex: "bank_name", key: "bank_name" },
    { title: "Account Number", dataIndex: "account_number", key: "account_number" },
    { title: "Ifsc Code", dataIndex: "ifsc_code", key: "ifsc_code" },
    { title: "Wallet", dataIndex: "wallet", key: "wallet", render: (item) => Math.round(item) },
    {
      title: "Update Rank",
      dataIndex: "distributor_rank_id",
      key: "update_rank",
      render: (id, data) => (
        <FormGroup>
          <Input
            type="select"
            value={data?.distributor_rank_id?._id}
            onChange={(e) => DistributorUpdate(e, data?._id, e.target.value, "rank")}
          >
            {AllRank?.map((item) => (
              <option value={item._id} key={item._id}>{item.name} - {item.rank}</option>
            ))}
          </Input>
        </FormGroup>
      ),
    },
    {
      title: "Genealogy",
      dataIndex: "_id",
      key: "genealogy",
      render: (id, item) => (
        <Link className="btn btn-outline-success btn-sm" to={{ pathname: "all_distributor_child", state: item }}>Genealogy</Link>
      ),
    },
    {
      title: "Login Button",
      dataIndex: "_id",
      key: "genealogy",
      render: (id, item) => (
        <button className="btn btn-outline-success btn-sm" onClick={() => login(item)} >Login</button>
      ),
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "status",
      render: (id, item) => (
        <Label className="custom-toggle custom-toggle-warning">
          <Input
            onClick={(e) => DistributorUpdate(e, item?._id, item?.status)}
            defaultChecked={item?.status}
            type="checkbox"
          />
          <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On" />
        </Label>
      ),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id, item) => (
        <UncontrolledDropdown>
          <DropdownToggle className="btn-icon-only text-light" role="button" size="sm" onClick={(e) => e.preventDefault()}>
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem tag={Link} to={`all_distributor_transaction/${item?._id}`}><i className="ni ni-money-coins text-primary" />Transactions</DropdownItem>
            <DropdownItem tag={Link} to={`all_distributor_rank/${item?._id}`}><i className="ni ni-sound-wave text-danger" />Rank History</DropdownItem>
            <DropdownItem tag={Link} to={{ pathname: `update_distributor/${item?._id}`, state: item }}><i className="ni ni-settings text-danger" />Update Profile</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Invoice",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  const csvData = [
    ["ID", "Name", "Phone", "Rank", "DOJ", "Parent Name", "Wallet"],
    ...searchFilter?.map((item, idx) => [
      idx + 1,
      item?.name,
      item?.phone,
      item?.distributor_rank_id?.rank,
      moment(item?.created_at).format("ll"),
      item?.parent_id?.name ?? "NA",
      Math.round(item?.wallet),
      item?.account_number,
      item?.bank_name,
      item?.ifsc_code,
    ]),
  ];
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Button color="white" onClick={handlePrint}>
              Export PDF
            </Button>

            <CSVLink
              className="btn btn-white"
              filename="order.csv"
              data={csvData}
            >
              Export to CSV
            </CSVLink>
            <Button color="white" onClick={handlePrint}>
              Print
            </Button>
          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-5">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <Row>
                      <SearchViaDate
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        action={SearchDate}
                      />
                      <Col>
                        <FormGroup className="mb-0">
                          <Label for="phoneSearch">
                            Search by phone or name
                          </Label>
                          <Input
                            className="form-control-alternative border shadow"
                            placeholder="Enter phone number or name"
                            id="phoneSearch"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div ref={printRef}>
                  <Table
                    columns={columns}
                    loading={ready}
                    dataSource={searchFilter}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllDistributor;
