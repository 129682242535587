import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Spinner,
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import queryString from "query-string";
import Dataservices from "../../services/requestApi";
import Header from "components/Headers/Header";
import { useParams, useHistory } from "react-router-dom";
import { Image } from "antd";

const UpdateDistributor = ({ location }) => {
  const [ready, setReady] = useState(false);
  let history = useHistory();
  const { id } = useParams();
  const [updateData, setUpdateData] = useState({
    name: "",
    email: "",
    phone: "",
    aadhar_no: "",
    pan_no: "",
    father_name: "",
    aadhar_Card: "",
    pancard: "",
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    Address: "",
    nominee: "",
    ref_code: "",
    password: "",
  });
  useEffect(() => {
    const UpdateData = () => {
      if (location.state) {
        const data = location.state;
        delete data?.distributor_rank_id;
        delete data?.free_child;
        delete data?.isFreeChild;
        delete data?.password;
        delete data?.wallet;
        delete data?.type;
        delete data?.created_at;
        delete data?.updated_at;
        delete data?.__v;
        delete data?.parent_id;
        console.log(location.state);
        setUpdateData(location.state);
      }
    };
    UpdateData();
  }, []);
  const UpdateProfile = async (e) => {
    e.preventDefault();
    try {
      setReady(true);
      const Data = updateData;
      if (!updateData.password?.length > 0) {
        delete Data.password;
      }
      const res = await Dataservices.updateDistributor(
        id,
        queryString.stringify(Data)
      );
      if (res.data) {
        setReady(false);
        history.goBack();
      }
    } catch (e) {
      setReady(false);
    }
  };
  return (
    <>
      <Header show={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Update Distributor</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  role="form"
                  onSubmit={UpdateProfile}
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col lg="12">
                      <div
                        style={{
                          width: 150,
                          height: 150,
                          overflow: "hidden",
                          margin: "auto auto 50px",
                          border: "1px solid grey",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={updateData?.profile_pic}
                          className="img-fluid rounded-circle"
                          width="150"
                          height="150"
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="Name"
                        >
                          Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.name}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              name: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          disabled
                          readOnly
                          value={updateData?.email}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              email: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="phone"
                        >
                          Phone
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.phone}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              phone: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.password}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              password: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="aadhar_no"
                        >
                          Aadhar Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.aadhar_no}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              aadhar_no: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="pan_no"
                        >
                          Pan Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.pan_no}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              pan_no: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="aadhar_no"
                        >
                          Aadhar Front
                        </label>
                        <Image
                          src={updateData?.aadhar_Card}
                          className="img-fluid"
                          height="150"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="aadhar_no"
                        >
                          Aadhar Back
                        </label>
                        <Image
                          src={updateData?.aadhar_Card_Back}
                          height="150"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label d-block mb-3"
                          htmlFor="pan_no"
                        >
                          Pan Card
                        </label>
                        <Image src={updateData?.pancard} height="150" />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="father_name"
                        >
                          Father name
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.father_name}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              father_name: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="bank_name"
                        >
                          Bank name
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.bank_name}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              bank_name: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="account_number"
                        >
                          Account Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.account_number}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              account_number: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="ifsc_code"
                        >
                          ifsc code
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.ifsc_code}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              ifsc_code: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label d-block"
                          htmlFor="Address"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={updateData?.Address}
                          onChange={(e) => {
                            setUpdateData({
                              ...updateData,
                              Address: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    className="my-4 btn-block"
                    type="submit"
                    color="warning"
                    disabled={ready}
                  >
                    {ready && <Spinner size="sm" color="light" />} Update
                    Profile
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UpdateDistributor;
