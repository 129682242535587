import React, { useState, useEffect, useRef } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Button,
} from "reactstrap";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
// APi
import queryString from "query-string";
import Dataservices from "../../services/requestApi";

// core components
import Header from "components/Headers/Header";
import SearchViaDate from "components/Filter/SearchViaDate";
import { Table } from "antd";
function AllOrder() {
  const printRef = useRef(null);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [AllOrder, setAllOrder] = useState([]);
  const history = useHistory();
  useEffect(() => {
    GetAllOrder(fromDate, toDate);
    return () => {
      GetAllOrder();
    };
  }, []);
  // GetAllOrder
  const GetAllOrder = async (from, end) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllOrder(from, end);
      setAllOrder(res.data?.data);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  };

  const updateProudctOrder = async (id, data) => {
    try {
      await Dataservices.updateProudctOrder(id, queryString.stringify(data));
      GetAllOrder();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteOrder = async (id) => {
    // try {
    //   await Dataservices.deleteOrder(id);
    //   GetAllOrder();
    // } catch (e) {
    //   console.log(e);
    // }
  };
  const searchFilter = AllOrder.filter((data) => {
    const name = data?.contact_name;
    const phone = data?.contact_phone;
    const FilterName = name.toLowerCase().includes(search.toLowerCase());
    const FilterPhone = phone.toLowerCase().includes(search.toLowerCase());
    if (FilterName) {
      return FilterName;
    }
    if (FilterPhone) {
      return FilterPhone;
    }
  });
  const SearchDate = async () => {
    if (fromDate && toDate) {
      GetAllOrder(fromDate, toDate);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Invoice",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });
  const csvData = [
    ["ID", "Name", "Phone", "status", "Created on"],
    ...searchFilter?.map((item, idx) => [
      idx + 1,
      item?.contact_name,
      item?.contact_phone,
      item?.status,
      moment(item?.created_at).format("ll"),
    ]),
  ];
  function InvoiceGenerate(id) {
    const order = AllOrder;
    const CurrentOrder = order.find((item) => item._id === id);
    history.push({ pathname: "/admin/invoice", state: CurrentOrder });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Phone",
      dataIndex: "contact_phone",
      key: "contact_phone",
    },
    {
      title: "Status",
      dataIndex: "order_id",
      key: "status",
      render: (id, item) => (
        <FormGroup className="w-75 mb-0">
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            onChange={(e) => {
              updateProudctOrder(item._id, {
                status: e.target.value,
              });
            }}
            defaultValue={item.status}
          >
            <option value="">Update Status</option>
            <option value="In Process">In Process</option>
            <option value="Order Dispatched">Order Dispatched</option>
            <option value="Out for Delivery">Out for Delivery</option>
            <option value="Order Delivered">Order Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </Input>
        </FormGroup>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("ll"),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id, item) => (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              to={{
                pathname: `distributor-order/${item?.distributor?._id}`,
                state: {
                  transactionId: item._id,
                  userData: item?.distributor,
                },
              }}
              tag={Link}
            >
              <i className="fas fa-eye text-primary" /> View Order
            </DropdownItem>
            <DropdownItem to={`orders-transaction/${item?._id}`} tag={Link}>
              <i className="ni ni-money-coins text-danger" /> Transactions
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
    {
      title: "Invoice",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <>
          <Button
            onClick={() => InvoiceGenerate(id)}
            size="sm"
            color="dark"
            outline
          >
            <span className="ni ni-active-40"></span>
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Col>
              <Button color="white" onClick={handlePrint}>
                Export PDF
              </Button>

              <CSVLink
                className="btn btn-white"
                filename="order.csv"
                data={csvData}
              >
                Export to CSV
              </CSVLink>
              <Button color="white" onClick={handlePrint}>
                Print
              </Button>
            </Col>
            <Card className="shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <FormGroup className="mb-0">
                      <Label for="toDate" className="d-block">
                        &nbsp;
                      </Label>
                      <Input
                        id="title"
                        placeholder="Search by Phone / Name"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <SearchViaDate
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    action={SearchDate}
                  />
                </Row>
              </CardHeader>
              <CardBody>
                <div ref={printRef}>
                  <Table
                    id="AllOrder"
                    columns={columns}
                    loading={ready}
                    dataSource={searchFilter}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllOrder;
