import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToWords } from 'to-words';
// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    CardBody,
    Table,
    Button,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";

import LOGO from "../../assets/img/brand/logo.png";
import SIGN from "../../assets/img/brand/sign.png";
import moment from "moment";
function Invoice() {
    const invoiceRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { state: InvoiceData } = useLocation();
    const [userData, setUserData] = useState(undefined);
    const [totalGst, setTotalGst] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const toWords = new ToWords();
    useEffect(() => {
        const getData = async (e) => {
            try {
                setLoading(true);
                setUserData({
                    name: InvoiceData?.distributor?.name,
                    email: InvoiceData?.distributor?.email,
                    phone: InvoiceData?.distributor?.phone,
                    Address: InvoiceData?.distributor?.Address,
                    userType: InvoiceData?.distributor?.type,
                    userRank: InvoiceData?.distributor?.distributor_rank_id?.rank,
                    userdiscount:
                    InvoiceData?.distributor?.distributor_rank_id?.ediscount,
                });

                let subtotal = 0;
                let gst = 0;

                for (const item of InvoiceData?.products) {
                    const amount = Number(item?.quantity) * Number(item?.amount);
                    const gstAmount = (amount * item?.product?.gst) / 100;

                    gst += gstAmount;
                    subtotal += amount;
                }
                setTotalGst(gst);
                setSubTotal(Math.round(subtotal));
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        };
        getData();
        return () => {
            getData();
        };
    }, []);
    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: "Invoice",
        onAfterPrint: () => console.log("Printed PDF successfully!"),
    });
    console.log(InvoiceData);
    function RenderTotalAmount(qty, amountValue, GST) {
        const amount = Number(qty) * Number(amountValue);
        const GSTAmount = amount + (amount * GST) / 100;
        return Math.round(GSTAmount);
    }
    function RenderTotalgst(qty, amountValue, GST) {
        const amount = Number(qty) * Number(amountValue);
        const GSTAmount = (amount * GST) / 100;

        return Math.round(GSTAmount);
    }
    return (
        <>
            <Header show={false} />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col>
                                        <h3 className="mb-0">
                                            Invoice {InvoiceData?.distributor?.name}
                                        </h3>
                                    </Col>
                                    <Col className="text-right">
                                        <Button color="dark" onClick={handlePrint} size="sm">
                                            <i className="ni ni-ungroup mr-1" /> Print Invoice
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginTop: 5,
                                        marginRight: 15,
                                        marginLeft: 15,
                                    }}
                                    ref={invoiceRef}
                                >
                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <h1 className="font-weight-bold" style={{fontSize: 20}}>
                                            TAX INVOICE
                                        </h1>
                                    </div>
                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "50%",
                                            textAlign: "left",
                                        }}
                                    >
                                        <h4>
                                            Order Id:{" "}
                                            <span className="text-dark">{InvoiceData?.order_id}</span>
                                        </h4>
                                    </div>
                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <h4>
                                            Date:{" "}
                                            <span className="text-dark">
                        {moment(InvoiceData?.created_at?.split("T")[0]).format(
                            "ll"
                        )}
                      </span>
                                        </h4>
                                    </div>

                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "50%",
                                            margin: "30px 0 ",
                                        }}
                                    >
                                        <img src={LOGO} height={70} width={90}/>
                                        <h3 className="mt-2">
                                            Invoice No:{" "}
                                            <span className="text-dark">
                            {InvoiceData?.order_id?.split('EF0000')[1]}
                          </span>
                                        </h3>
                                    </div>
                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "50%",
                                            textAlign: "end",
                                            marginBottom: 50,
                                        }}
                                    >
                                        {/*<address>*/}
                                        {/*  <strong>Sold By:</strong>*/}
                                        {/*  <br />*/}
                                        {/*  <b>Newision Shoppers lifestyle Pvt. Ltd.</b>*/}
                                        {/*  <br />*/}
                                        {/*  Lane no. 4, Geetanjali enclave*/}
                                        {/*  <br />*/}
                                        {/*  Mothrowala, Dheradun, UK (248001)*/}
                                        {/*  <br />*/}
                                        {/*  <b>CIN NO : U46901UT2023PTC016191</b>*/}
                                        {/*  <br />*/}
                                        {/*  <b>GST NO.: 05AAJCN1222F1ZY</b>*/}
                                        {/*</address>*/}
                                    </div>
                                    {/* Order By */}
                                    <div
                                        style={{
                                            flex: "0 0 auto",
                                            width: "50%",
                                        }}
                                    >
                                        {/*<address>*/}
                                        {/*  <strong>Order By:</strong>*/}
                                        {/*  <br />*/}
                                        {/*  <b>{userData?.name}</b>*/}
                                        {/*  <br />*/}
                                        {/*  {userData?.email}*/}
                                        {/*  <br />*/}
                                        {/*  {userData?.phone}*/}
                                        {/*  <br />*/}
                                        {/*  {userData?.Address}*/}
                                        {/*</address>*/}
                                        <address>
                                            <strong>Sold By:</strong>
                                            <br/>
                                            <b>Explosive Fashion Crew Pvt. Ltd.</b>
                                            <br/>
                                            C/O Jaydatt Upreti, Balawala,
                                            <br/>
                                            Balawala Dehradun, Uttarakhand-248001
                                            <br/>
                                            <b>CIN NO : U46410UT2024PTC016748</b>
                                            <br/>
                                            <b>GST NO.: 05AAHCE9716B1ZU</b>
                                            <br/>
                                            <b>Email: explosivefashion1601@gmail.com</b>
                                            <br/>
                                            <b>Tan No: MRTE03947G</b>
                                            <br/>
                                            <b>Pan No: AAHCE9716B</b>
                                            <br/>
                                        </address>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "end",
                                            flex: "0 0 auto",
                                            width: "50%",
                                        }}
                                    >
                                        <address>
                                            <strong>Buyer/Shipped To:</strong>
                                            <br/>
                                            {InvoiceData?.contact_name}
                                            <br/>
                                            {InvoiceData?.contact_address}
                                            <br/>
                                            {InvoiceData?.city},{InvoiceData?.state},
                                            {InvoiceData?.pincode}
                                            <br/>
                                            <b>Mob No:</b> +91-{InvoiceData?.contact_phone}
                                            <br/>
                                            <b>Pan No:</b> {InvoiceData?.distributor?.pan_no}
                                            <br/>
                                            {InvoiceData?.distributor?.email}
                                        </address>
                                    </div>
                                    <Table bordered className="myInvoiceTable">
                                        <thead>
                                        <tr>
                                            <td>
                                                <strong>SR. NO</strong>
                                            </td>
                                            <td>
                                                <strong>HSN</strong>
                                            </td>
                                            <td>
                                                <strong>Item Code</strong>
                                            </td>
                                            <td>
                                                <strong>Item name</strong>
                                            </td>
                                            <td className="text-center">
                                                <strong>Qty</strong>
                                            </td>
                                            <td className="text-center">
                                                <strong>Size</strong>
                                            </td>
                                            <td>
                                                <strong>Amount</strong>
                                            </td>
                                            <td className="text-center">
                                                <strong>Tax</strong>
                                            </td>
                                            <td className="text-center">
                                                <strong>Tax Amount</strong>
                                            </td>

                                            <td className="text-end">
                                                <strong>Total</strong>
                                            </td>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {InvoiceData?.products?.map((data, idx) => (
                                            <tr key={data?._id}>
                                                <td>{idx + 1}</td>
                                                <td>{data?.product?.hsm}</td>
                                                <td>{data?.product?.product_code}</td>
                                                <td width="200px">{data?.product?.name}</td>
                                                <td className="text-center">{data?.quantity}</td>
                                                <td className="text-center">{data?.size}</td>
                                                <td className="text-center">
                                                    ₹ {data?.product?.price}
                                                </td>
                                                <td className="text-center">{data?.product?.gst}%</td>
                                                <td className="text-center">
                                                    ₹
                                                    {RenderTotalgst(
                                                        data?.quantity,
                                                        data.product?.price,
                                                        data.product?.gst
                                                    )}
                                                </td>

                                                <td className="text-end">
                                                    ₹
                                                    {RenderTotalAmount(
                                                        data?.quantity,
                                                        data.product?.price,
                                                        data.product?.gst
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr style={{border: "1px solid transparent"}}>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line"></td>
                                            <td className="thick-line">
                                                <strong>Subtotal</strong>
                                            </td>
                                            <td className="thick-line text-end">₹ {subTotal}</td>
                                        </tr>

                                        {userData?.userType !== "user" && (
                                            <tr style={{border: "1px solid transparent"}}>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line">
                                                    <strong>Discount</strong>
                                                </td>
                                                <td className="no-line text-end">
                                                    {" - "} ₹
                                                    {userData?.userRank <= 20
                                                        ? 0
                                                        : subTotal -
                                                        Math.round(
                                                            (subTotal * (100 - userData?.userdiscount)) /
                                                            100
                                                        )}
                                                </td>
                                            </tr>
                                        )}

                                        {(InvoiceData?.state === 'Uttarakhand' || InvoiceData?.state === 'uttarakhand') ? (
                                            <>
                                                <tr style={{border: "1px solid transparent"}}>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line">
                                                        <strong>CGST</strong>
                                                    </td>
                                                    <td className="no-line text-end">
                                                        ₹ {Math.round(totalGst / 2)}
                                                    </td>
                                                </tr>
                                                <tr style={{border: "1px solid transparent"}}>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line"></td>
                                                    <td className="no-line">
                                                        <strong>SGST</strong>
                                                    </td>
                                                    <td className="no-line text-end">
                                                        ₹ {Math.round(totalGst / 2)}
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr style={{border: "1px solid transparent"}}>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line"></td>
                                                <td className="no-line">
                                                    <strong>IGST</strong>
                                                </td>
                                                <td className="no-line text-end">
                                                    ₹ {Math.round(totalGst)}
                                                </td>
                                            </tr>)}

                                        <tr>
                                            <td className="no-line" colSpan="2"><strong>Rupees in Words :-</strong></td>
                                            <td className="no-lin" colSpan="6">{
                                                subTotal && totalGst ? toWords.convert(
                                                    userData?.userType !== "user"
                                                        ? Math.round(
                                                            subTotal +
                                                            totalGst -
                                                            Math.round(
                                                                userData?.userRank <= 20
                                                                    ? 0
                                                                    : subTotal -
                                                                    (subTotal *
                                                                        (100 - userData?.userdiscount)) /
                                                                    100
                                                            )
                                                        )
                                                        : Math.round(subTotal + totalGst)
                                                    // 123
                                                    , {currency: true}) : ''}</td>
                                            <td className="no-line">
                                                <strong>Total</strong>
                                            </td>
                                            <td className="no-line text-end">
                                                ₹
                                                {/* {userData?.userType !== "user"
                            ? Math.round(
                                subTotal +
                                  totalGst -
                                  Math.round(
                                    userData?.userRank <= 20
                                      ? 0
                                      : subTotal -
                                          (subTotal *
                                            (100 - userData?.userdiscount)) /
                                            100
                                  ) +
                                  299
                              )
                            : Math.round(subTotal + totalGst + 299)} */}
                                                {userData?.userType !== "user"
                                                    ? Math.round(
                                                        subTotal +
                                                        totalGst -
                                                        Math.round(
                                                            userData?.userRank <= 20
                                                                ? 0
                                                                : subTotal -
                                                                (subTotal *
                                                                    (100 - userData?.userdiscount)) /
                                                                100
                                                        )
                                                    )
                                                    : Math.round(subTotal + totalGst)}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <div className="mt-2">
                                        <h5>1. I accept the product recieved asaforesaid are in a condition
                                            and I have personally checked and I am satisfied with the entire
                                            product range as per invoice.</h5>
                                        <h5>2. I accept and agree the money paid under this invoice will not
                                            refundable under any circumstances after
                                            30 days from the date of payment made.</h5>
                                        <h5>3. All disputes subjected to dehradun jurisdiction only.</h5>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "start",
                                            flex: "0 0 auto",
                                            marginTop: "40px",
                                            width: "70%"
                                            // marginLeft: "780px"
                                        }}>

                                    </div>
                                    <div
                                        style={{
                                            textAlign: "end",
                                            flex: "0 0 auto",
                                            width: "30%",
                                            marginTop: "20px",
                                            // marginLeft: "100px"
                                        }}
                                    >
                                        <div style={{flex: "0 0 auto", textAlign: "center"}}>
                                            <div><b>Authorised Signatory :-</b></div>
                                            <div><img src={SIGN} height={70} width={200}/></div>
                                            <div>Explosive Fashion Crew Pvt. Ltd.</div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Invoice;
