import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Table,
  Badge,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";

function AllOrderTransaction() {
  const [AllProductOrder, setAllProductOrder] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const { order_id } = useParams();
  useEffect(() => {
    GetAllOrder();
    return () => {
      GetAllOrder();
    };
  }, []);
  // GetAllOrder
  const GetAllOrder = useCallback(
    async (e) => {
      try {
        setReady(true);
        const res = await Dataservices.getAllOrderTransaction(order_id);
        console.log(res.data.Distributor);
        setAllProductOrder(res.data.Distributor);
        setReady(false);
      } catch (e) {
        console.log(e);
      }
    },
    [order_id]
  );

  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Orders Transactions</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        id="title"
                        placeholder="Search Banner"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Distributor Name</th>
                        <th scope="col">Distributor Email</th>
                        <th scope="col">Distributor Phone</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Channel</th>
                        <th scope="col">Mode</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllProductOrder &&
                        AllProductOrder.map((item, ids) => (
                          <tr key={item._id}>
                            <th scope="row">{ids + 1}</th>
                            <td>{item.distributor?.name}</td>
                            <th scope="row">{item.distributor?.email}</th>
                            <td>{item.distributor?.phone}</td>
                            <td>Rs. {item?.amount}</td>
                            <td>{item?.channel}</td>
                            <td>
                              <Badge
                                color={
                                  item?.transaction_mode === "credit"
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {item?.transaction_mode}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllOrderTransaction;
