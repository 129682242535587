import React, { useEffect, useState } from "react";
import { Image, Select } from "antd";
import { FormGroup, Input, Button, Form, Col, Row } from "reactstrap";
import { BASEURL } from "services/http-common";
import Resizer from "react-image-file-resizer";

const { Option } = Select;
const AddProductPackage = ({
  colour,
  setcolour,
  price,
  setprice,
  mrp,
  setmrp,
  setimage,
  thumbnail,
  setThumbnail,
  size,
  setSize,
  addvariant,
  statusupdate,
  updatevariant,
}) => {
  const [toggle, setToggle] = useState(false);
  const fileHandler = (e) => {
    const file = [...e.target.files];
    if (file) {
      setToggle(true);
      setimage([]);
      setThumbnail([]);
      file.forEach((file) => {
        Resizer.imageFileResizer(
          file,
          500,
          500,
          "JPEG",
          90,
          0,
          (uri) => {
            setimage((prev) => [...prev, uri]);
            setThumbnail((prev) => [...prev, uri]);
          },
          "file",
          500,
          500
        );
      });
    } else {
      setimage(null);
      setThumbnail(null);
    }
  };
  useEffect(() => {
    if (thumbnail == null) {
      setToggle(false);
    }
  }, [thumbnail]);
  function handleChange(val) {
    const size = [];
    for (let items of val) {
      size.push(items);
      // setUnit(value);
    }
    setSize(size);
  }
  return (
    <>
      <Form role="form">
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            Select Colour
          </label>
          <Input
            className="form-control-alternative"
            id="title"
            placeholder="Enter Title"
            type="color"
            value={colour}
            onChange={(e) => setcolour(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="image">
            Image
          </label>
          <Input
            className="form-control-alternative"
            id="image"
            type="file"
            accept="image/*"
            onChange={fileHandler}
            multiple
          />
          {/* {thumbnail && <Image height={200} src={`${BASEURL.ENDPOINT_URL}${thumbnail}`} className="mt-3" />} */}
          <Row>
            {!toggle &&
              thumbnail &&
              thumbnail.map((item) => (
                <Col lg="3" key={item._id} className="mt-3">
                  <Image src={`${item.image}`} className="img-fluid" alt="default" />
                </Col>
              ))}
            {toggle &&
              thumbnail &&
              thumbnail.map((item) => (
                <Col lg="3" key={item._id} className="mt-3">
                  <Image key={item._id} src={URL.createObjectURL(item)} className="img-fluid" alt="banner" />
                </Col>
              ))}
          </Row>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="size">
            Enter Size
          </label>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter Size Name"
            value={size}
            onChange={handleChange}
          >
            <Option value="sm">sm</Option>
            <Option value="md">md</Option>
            <Option value="lg">lg</Option>
            <Option value="xl">xl</Option>
            <Option value="xxl">xxl</Option>
            <Option value="xxxl">xxxl</Option>
          </Select>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="price">
            Enter Price
          </label>
          <Input
            className="form-control-alternative"
            id="price"
            placeholder="Enter Price"
            type="number"
            value={price}
            onChange={(e) => setprice(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="mrp">
            Enter Mrp
          </label>
          <Input
            className="form-control-alternative"
            id="mrp"
            placeholder="Enter Mrp"
            type="number"
            value={mrp}
            onChange={(e) => setmrp(e.target.value)}
          />
        </FormGroup>
        {statusupdate ? (
          <Button className="my-4 btn-block" type="submit" color="primary" onClick={updatevariant}>
            Update Variant
          </Button>
        ) : (
          <Button className="my-4 btn-block" type="submit" color="primary" onClick={addvariant}>
            Add Variant
          </Button>
        )}
      </Form>
    </>
  );
};
export default AddProductPackage;
